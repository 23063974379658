import { Button, Grid, List, Row, Skeleton } from "antd";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { CardTitle, ColumnComponent } from "../../components/ui";
import DataContext from "../../context/DataContext";
import {
  SolutionOutlined,
  FileSearchOutlined,
  ToolOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import AuthContext from "../../context/AuthContext";
import QuickbooksContext from "../../context/QuickbooksContext";
import {
  exportDetailFile,
  exportLogs,
  exportReport,
  exportSummaryFile,
} from "../../utils/func/ExportFile";
import { ModalFilterReport } from "../../components/report";
import { useParams } from "react-router-dom";
import {
  FilterProps,
  InvoiceProps,
  UserActionProps,
} from "../../interfaces/interfaces";
import { FILTERS_INITIAL_STATUS } from "../../utils/data";

interface DataType {
  name: string;
  description: string;
  icon: ReactNode;
  endpoint: "summary" | "detail";
  label: string;
  action: () => void | null;
}

export const ReportClient = () => {
  const { idClient } = useParams();
  const { dataLoading } = useContext(DataContext);
  const { clientInfo } = useContext(AuthContext);
  const { getData } = useContext(QuickbooksContext);
  const { getData: getListData, getReportData } = useContext(DataContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();

  useEffect(() => {
    if (idClient) {
      getListData("status", idClient);
      getListData("customer", idClient);
    }
  }, [idClient]);

  const list: DataType[] = [
    {
      name: "Custom Report",
      description:
        "The custom report provides a flexible and customizable way to analyze data based on specific criteria such as Status, Customer, and Date Range. It allows users to generate detailed insights into various aspects of operations, customer interactions, and performance within a specified time frame.",
      icon: <SolutionOutlined />,
      endpoint: "summary",
      label: "Filter",
      action: () => setIsModalOpen(true),
    },
    {
      name: "Summary Report",
      description:
        "The Summary report provides a comprehensive overview of outstanding invoices owed to your organization by clients. This report is designed to facilitate financial analysis and help manage accounts receivable effectively.",
      icon: <ToolOutlined />,
      endpoint: "summary",
      label: "Export",
      action: () =>
        clientInfo &&
        getData({
          endpoint: "summary",
          idClient: clientInfo?._id,
          next: (data) => exportSummaryFile(data),
        }),
    },
    {
      name: "Detail Report",
      description:
        "The Detail Report provides a comprehensive breakdown of outstanding invoices classified by aging categories, enabling detailed analysis of accounts receivable and aging trends.",
      icon: <FileSearchOutlined />,
      endpoint: "detail",
      label: "Export",
      action: () =>
        clientInfo &&
        getData({
          endpoint: "detail",
          idClient: clientInfo?._id,
          next: (data) =>
            exportDetailFile(data, {
              selectedCurrent: [],
              selected_1_30: [],
              selected_31_60: [],
              selected_61_90: [],
              selected_90: [],
            }),
        }),
    },
    {
      name: "Audit Log",
      description:
        "The User Activity Report provides an overview of user interactions and actions performed within the platform over a specified time period.",
      icon: <HistoryOutlined />,
      endpoint: "detail",
      label: "Export",
      action: () =>
        clientInfo &&
        getReportData(
          "logs",
          clientInfo._id,
          FILTERS_INITIAL_STATUS,
          (data: UserActionProps[]) => exportLogs(data)
        ),
    },
  ];
  return (
    <div className="general-view">
      <Row gutter={[24, 0]}>
        <ColumnComponent>
          <CardTitle title={clientInfo?.name + " Reports"} />
        </ColumnComponent>
        <ColumnComponent lg={24} xl={24}>
          <List
            loading={dataLoading}
            itemLayout="horizontal"
            dataSource={list}
            bordered
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button type="link" onClick={item.action}>
                    {item.label}
                  </Button>,
                ]}
              >
                <Skeleton avatar title={false} loading={dataLoading} active>
                  <List.Item.Meta
                    avatar={!xs && item.icon}
                    title={item.name}
                    description={!xs && item.description}
                  />
                </Skeleton>
              </List.Item>
            )}
          />
        </ColumnComponent>
      </Row>
      <ModalFilterReport
        open={isModalOpen}
        onSubmit={(filters: FilterProps) =>
          idClient &&
          getReportData("report", idClient, filters, (data: InvoiceProps[]) =>
            exportReport(data)
          )
        }
        onCancel={() => setIsModalOpen(false)}
      />
    </div>
  );
};
