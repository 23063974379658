import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Table,
  TablePaginationConfig,
  Tooltip,
} from "antd";
import {
  EmailPreviewProps,
  InvoiceProps,
  StatusProps,
} from "../../interfaces/interfaces";
import TextArea from "antd/es/input/TextArea";
import QuickbooksContext from "../../context/QuickbooksContext";
import dayjs, { Dayjs } from "dayjs";
import {
  EMAIL_PREVIEW_INITIAL_STATE,
  INVOICE_INITIAL_STATE,
} from "../../utils/data";
import { useAlertPrevent, useDemoModal } from "../../hooks";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { DownOutlined, DeleteOutlined } from "@ant-design/icons";
import { MenuProps } from "antd/lib";
import { FilterValue } from "antd/es/table/interface";
import { validateEmailsString } from "../../utils/func/utils";
import { EmailPreviewModal } from "../reminder";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

interface Props {
  values: InvoiceProps;
  form: FormInstance<any>;
  options: StatusProps[];
  autoSave: boolean;
  isClosing: boolean;
  onSubmit: (
    newNote?: string,
    emailAddress?: string,
    phoneNumber?: string,
    newDate?: Dayjs,
    next?: () => void
  ) => void;
  onClose: () => void;
}

export const CollectionsForm = ({
  values,
  form,
  options,
  isClosing,
  onSubmit,
  onClose,
  autoSave,
}: Props) => {
  const { clientInfo, isDemoEnv, role } = useContext(AuthContext);
  const navigate = useNavigate();
  const { DemoModal, openModalDemo } = useDemoModal();
  const { modalAlertPrevent, openAlertPrevent } = useAlertPrevent();
  const {
    handleEditSelectedInvoice,
    getReminderPreview,
    sendReminderAfterPreview,
    qbLoading,
    customerInfo,
  } = useContext(QuickbooksContext);
  const [newNote, setNewNote] = useState("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [hasChanged, setHasChanged] = useState(false);
  const [emailHasChanged, setEmailHasChanged] = useState(false);
  const [formattedItemList, setformattedItemList] = useState<
    { label: string; value: string }[]
  >([]);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({});
  const [dataPreview, setDataPreview] = useState<EmailPreviewProps>(
    EMAIL_PREVIEW_INITIAL_STATE
  );

  useEffect(() => {
    setTableParams({
      pagination: {
        pageSize: 5,
        total: values.CollectionDetails?.LastNotes?.length,
        onChange: (_, pageSize) => {
          setTableParams((prev) => {
            return {
              ...prev,
              pagination: {
                ...prev.pagination,
                pageSize: pageSize,
              },
            };
          });
        },
      },
    });
  }, [values]);

  useEffect(() => {
    if (values) {
      form.setFieldsValue(values);
    } else {
      form.setFieldsValue(INVOICE_INITIAL_STATE);
    }
  }, [form, values]);

  // useEffect(() => {
  //   let emailAddress = "";
  //   if (values.CollectionDetails?.EmailAddress) {
  //     emailAddress = values.CollectionDetails?.EmailAddress;
  //   }
  //   setEmailAddress(emailAddress);
  //   setPhoneNumber(values?.CollectionDetails?.PhoneNumber);
  //   form.setFieldsValue({
  //     emailAddress,
  //   });
  // }, [values.CollectionDetails]);

  useEffect(() => {
    let emailFromCustomer =
      customerInfo.CollectionsProfile?.EmailAddress ||
      values.BillEmail?.Address ||
      customerInfo?.PrimaryEmailAddr?.Address ||
      values.CollectionDetails?.EmailAddress;

    if (emailFromCustomer) {
      setEmailAddress(emailFromCustomer);
      form.setFieldsValue({
        emailAddress: emailFromCustomer,
      });
    }
    let phoneFromCustomer =
      customerInfo?.CollectionsProfile?.PhoneNumber ||
      customerInfo?.PrimaryPhone?.FreeFormNumber ||
      customerInfo?.Mobile?.FreeFormNumber ||
      values.CollectionDetails?.PhoneNumber ||
      "";

    setPhoneNumber(phoneFromCustomer);
  }, [customerInfo]);

  useEffect(() => {
    if (autoSave) {
      if (hasChanged || emailHasChanged) {
        openAlertPrevent();
        setHasChanged(false);
      }
      onClose();
    } else {
      setHasChanged(false);
      setEmailHasChanged(false);
    }
  }, [autoSave]);

  useEffect(() => {
    let data = options.map((item) => {
      return {
        value: item._id || "",
        label: item.Label,
      };
    });
    setformattedItemList(data);
  }, [options]);

  useEffect(() => {
    if (isClosing) {
      if (hasChanged || emailHasChanged) {
        openAlertPrevent();
        setHasChanged(false);
      }
      onClose();
    } else {
      setHasChanged(false);
      setEmailHasChanged(false);
    }
  }, [isClosing]);

  useEffect(() => {
    if (emailAddress) {
      setIsValidEmail(validateEmailsString(emailAddress));
    } else {
      setIsValidEmail(false);
    }
  }, [emailAddress]);

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const items: MenuProps["items"] = [
    {
      label: "Send + Attachment",
      key: "1",
      onClick: () => sendReminderEmail({ attachment: true }),
    },
    {
      label: "Reminder Settings",
      key: "2",
      onClick: () => navigate(`/customer/${clientInfo?._id}`),
    },
  ];

  const sendReminderEmail = ({ attachment }: { attachment?: boolean }) => {
    if (isDemoEnv) {
      openModalDemo();
    } else {
      if (clientInfo) {
        getReminderPreview({
          idClient: clientInfo._id,
          idCustomer: values.CustomerRef.value,
          DocNumber: values.DocNumber,
          next: (dataPreview) => {
            setDataPreview({ ...dataPreview, preview: true, attachment });
          },
          onError: () => {},
        });
      }
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        initialValues={values}
        form={form}
        onFinish={() => {
          onSubmit(newNote, emailAddress, phoneNumber);
          setNewNote("");
          setHasChanged(false);
          setEmailHasChanged(false);
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Status" className="collections-form__form-item">
              <Select
                showSearch
                placeholder="Select an status"
                optionFilterProp="children"
                filterOption={filterOption}
                value={values.CollectionDetails.Status}
                onClick={() => setHasChanged(true)}
                onChange={(value) => {
                  handleEditSelectedInvoice("Status", value);
                }}
                options={formattedItemList}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Change"
              className="collections-form__form-item"
            >
              <DatePicker
                name="LastContact"
                format={"MM/DD/YYYY"}
                onClick={() => setHasChanged(true)}
                value={
                  values.CollectionDetails?.LastContact &&
                  dayjs(values.CollectionDetails?.LastContact)
                }
                className="collections-form__form-item__date-picker"
                allowClear={false}
                onChange={(date) => {
                  setHasChanged(true);
                  handleEditSelectedInvoice("LastContact", date);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Email Address"
              name="emailAddress"
              className="collections-form__form-item"
              // rules={[
              //   {
              //     type: "email",
              //     message: "The input is not valid E-mail!",
              //   },
              // ]}
            >
              <Tooltip title="You can edit Customer's email address from Customer Information">
                <Input
                  name="emailAddress"
                  placeholder="Email"
                  value={qbLoading ? "" : emailAddress}
                  disabled
                />
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Phone Number"
              className="collections-form__form-item"
            >
              <Tooltip title="You can edit Customer's Phone Number from Customer Information">
                <Input
                  name="Phone Number"
                  placeholder="Phone Number"
                  multiple
                  value={qbLoading ? "" : phoneNumber}
                  disabled
                />
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="New Note" className="collections-form__form-item">
              <TextArea
                rows={3}
                placeholder="New Note"
                value={newNote}
                onChange={(event) => {
                  setHasChanged(true);
                  setNewNote(event.target.value);
                }}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item className="collections-form__form-item__button">
              <Button
                type="primary"
                htmlType="submit"
                disabled={!hasChanged}
                loading={qbLoading}
              >
                Save Changes
              </Button>
            </Form.Item>
          </Col>
          <Col span={8}>
            {values.CustomerRef && (
              <Form.Item className="collections-form__form-item__button">
                <Button
                  type="primary"
                  onClick={() => {
                    if (clientInfo) {
                      onClose();
                      navigate(
                        `/detail/${clientInfo?._id}/?add_filter=${values.CustomerRef.value}`
                      );
                    }
                  }}
                >
                  See All Invoices
                </Button>
              </Form.Item>
            )}
          </Col>
          {role !== "DEMO" && (
            <Col span={8}>
              <Form.Item className="collections-form__form-item__button">
                <Tooltip title="Before sending a reminder, please make sure you have the email address of the Customer">
                  <Dropdown.Button
                    icon={<DownOutlined />}
                    loading={qbLoading}
                    menu={{ items }}
                    disabled={
                      !isValidEmail || !(values.TransactionType === "Invoice")
                    }
                    onClick={() => sendReminderEmail({ attachment: false })}
                  >
                    Send Reminder
                  </Dropdown.Button>
                </Tooltip>
              </Form.Item>
            </Col>
          )}

          {values.CollectionDetails?.LastNotes &&
            values.CollectionDetails?.LastNotes?.length > 0 && (
              <Col span={24}>
                <Table
                  title={() => "Previous Notes"}
                  rowKey={(record) => record._id.toString()}
                  columns={[
                    {
                      title: "User",
                      dataIndex: "user",
                      key: "user",
                      render: (text) => text.name,
                    },
                    {
                      title: "Date",
                      dataIndex: "date",
                      key: "date",
                      render: (_, { date }) => (
                        <>
                          {date ? dayjs(date).format("MM/DD/YYYY HH:mm") : ""}
                        </>
                      ),
                    },
                    {
                      title: "Note",
                      dataIndex: "body",
                      key: "body",
                    },
                    {
                      title: "Action",
                      key: "action",
                      render: (_, { date }) => (
                        <Tooltip title="Delete Note">
                          <Button
                            icon={<DeleteOutlined />}
                            type="link"
                            onClick={() => {
                              if (clientInfo) {
                                handleEditSelectedInvoice(
                                  "LastContact",
                                  dayjs(date)
                                );
                              }
                            }}
                          ></Button>
                        </Tooltip>
                      ),
                    },
                  ]}
                  dataSource={values.CollectionDetails.LastNotes}
                  pagination={tableParams.pagination}
                  size="small"
                  // pagination={{ pageSize: 5 }}
                />
              </Col>
            )}
        </Row>
      </Form>
      {modalAlertPrevent({
        type: "autosave",
        next: () => {
          onSubmit(newNote, emailAddress, phoneNumber);
          setNewNote("");
        },
      })}
      <DemoModal />
      <EmailPreviewModal
        visible={dataPreview.preview}
        emailData={dataPreview as any}
        onCancel={() => setDataPreview(EMAIL_PREVIEW_INITIAL_STATE)}
        onSend={(emailPreview) => {
          if (clientInfo)
            sendReminderAfterPreview({
              idClient: clientInfo._id,
              idCustomer: values.CustomerRef.value,
              DocNumber: values.DocNumber,
              emailPreview: emailPreview,
              next: () => {
                onSubmit(
                  "Reminder sent via email",
                  emailAddress,
                  phoneNumber,
                  dayjs()
                );
                setDataPreview(EMAIL_PREVIEW_INITIAL_STATE);
              },
            });
        }}
      />
    </>
  );
};
